import transactionsService from "../../../../services/transactionsService";
import $formatNumber from "../../../../mixins/formatNumber";

export default {
    name: "failed-ether-injection",
    components: {},
    props: [],
    mixins: [$formatNumber],
    data() {
        return {
            errors: "",
            statuses: "",
            failedEtherInjection: "",
            refIdSearch: null,
            orderIdSearch: null,
            statusText: "همه",
            status: null,
            typeText: "همه",
            type: null,
            currentPage: 1,
            tableHeaders: [
                "نام شرکت",
                "مقدار به ریال",
                "ارز پرداختی کاربر",
                "ارز دریافتی فروشگاه",
                "کارمزد لحظه‌ای",
                "کارمزد کاربر",
                "کارمزد تخلیه",
                "کد پیگیری بیت‌راه",
                "کد پیگیری فروشگاه",
                "علت خطا",
                "عملیات",
            ],
        };
    },
    computed: {},
    mounted() {
        this.getFailedEtherInjectionFunc();
        this.getErrorFunc();
        this.getStatusFunc();
    },
    methods: {
        changeStatus(status, text) {
            this.status = status;
            this.statusText = text;
        },
        changeType(type, text) {
            this.type = type;
            this.typeText = text;
        },
        getFailedEtherInjectionFunc() {
            transactionsService
                .getFailedEtherInjection(
                    0,
                    localStorage.getItem("bitrahAdminAccessToken"),
                    this.type,
                    this.refIdSearch,
                    this.orderIdSearch
                )
                .then((response) => {
                    this.failedEtherInjection = response.data;
                });
        },
        submitFilterFailedEtherInjection() {
            transactionsService
                .getFailedEtherInjection(
                    0,
                    localStorage.getItem("bitrahAdminAccessToken"),
                    this.type,
                    this.refIdSearch,
                    this.orderIdSearch
                )
                .then((response) => {
                    this.currentPage = 1;
                    this.failedEtherInjection = response.data;
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        getPaginationData(id) {
            transactionsService
                .getFailedEtherInjection(
                    id - 1,
                    localStorage.getItem("bitrahAdminAccessToken"),
                    this.type,
                    this.refIdSearch,
                    this.orderIdSearch
                )
                .then((response) => {
                    this.failedEtherInjection = response.data;
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        getErrorFunc() {
            transactionsService.getErrors().then((response) => {
                this.errors = response.data;
            });
        },
        getStatusFunc() {
            transactionsService.getstatus().then((response) => {
                this.statuses = response.data;
            });
        },
        retry(data, index) {
            transactionsService
                .retryFailedEtherInjection(
                    data,
                    localStorage.getItem("bitrahAdminAccessToken")
                )
                .then((response) => {
                    if (response.data) {
                        this.failedEtherInjection.content.splice(index, 1);
                        this.$bvToast.toast(this.$i18n.t("toast.succesfullOperation"), {
                            title: this.$i18n.t("toast.successTitle"),
                            noCloseButton: true,
                            variant: "custom",
                            bodyClass:
                                "successFontColor rtl text-right font-weight-bold py-3",
                            headerClass:
                                "successBackgroundColor successFontColor  rtl text-right",
                            solid: false,
                        });
                    } else {
                        this.getFailedEtherInjectionFunc();
                        this.$bvToast.toast(this.$i18n.t("toast.failedOperation"), {
                            title: this.$i18n.t("toast.errorTitle"),
                            noCloseButton: true,
                            variant: "custom",
                            bodyClass:
                                "dangerBackgroundColor dangerFontColor rtl text-right",
                            headerClass:
                                "dangerBackgroundColor dangerFontColor  rtl text-right",
                            solid: false,
                        });
                    }
                });
        },
    },
};
